<template>
  <el-container class="app-container">
    <el-header style="margin:10px 0px;font-size:20px;">{{ $t('page.BasicInformation') }}</el-header>
    <!-- <el-main style=""> -->
    <el-row class="row-center">
      <el-col :span="16"><div class="grid-content" />
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px">
          <el-form-item label="平台/站点/店铺" prop="platformSiteShop">
            <el-cascader
              v-model="ruleForm.platformSiteShop"
              :disabled="disabled"
              clearable
              collapse-tags
              filterable
              style="width:90%"
              :options="alldatas"
              :props=" { value: 'value', label: 'dropDownName', children: 'dropDownList', multiple:true }"
            />
          </el-form-item>
          <el-form-item label="Style" prop="style">
            <el-select v-model="ruleForm.style" :disabled="disabled" clearable filterable :placeholder="$t('page.selectPlaceholder')" style="width:90%" @change="styleSelectChange">
              <el-option
                v-for="item in styleOptions"
                :key="item.id"
                :label="item.styleName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Color" :class="$i18n.locale">
            <el-select
              v-model="ruleForm.color"
              :disabled="disabled"
              clearable
              filterable
              collapse-tags
              id:placeholder="$t('page.selectPlaceholder')"
              style="width:90%"
              @change="colorSelectChange"
            >
              <el-option
                v-for="item in colorOptions"
                :key="item.id"
                :label="item.colorName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Size" :class="$i18n.locale">
            <el-select
              v-model="ruleForm.size"
              :disabled="disabled"
              clearable
              filterable
              collapse-tags
              id:placeholder="$t('page.selectPlaceholder')"
              style="width:90%"
              @change="sizeSelectChange"
            >
              <el-option
                v-for="item in sizeOptions"
                :key="item.id"
                :label="item.size"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Sku">
            <el-input v-model="ruleForm.sku" :disabled="disabled" :placeholder="$t('page.inputPlaceholder')" style="width:90%" @blur="inputBlur($event)" />
          </el-form-item>
          <el-form-item ref="returnRate" label="退货率" prop="returnRate">
            <el-input-number v-model="ruleForm.returnRate" controls-position="right" :min="0" :max="100" :precision="2" style="width:80%" placeholder="请输入0-100的数字,精确到两位小数" />
            %
          </el-form-item>
          <el-form-item ref="safeStockDays" label="安全库存天数" prop="safeStockDays">
            <div>
              <el-input-number v-model="ruleForm.safeStockDays" :precision="0" controls-position="right" :min="0" :max="999999" style="width:80%" placeholder="请输入0~999999整数" />
              天
            </div>

          </el-form-item>

          <el-row class="grid-content" type="flex" justify="center">
            <el-button type="primary" style="width:100px" :loading="Loadingcommit" @click="handlesubmitForm('ruleForm')">{{ $t('page.submit') }}</el-button>
            <el-button style="width:100px" @click="goBack">返回</el-button>
          </el-row>
        </el-form>
      </el-col>
    </el-row>

    <el-dialog
      title="提示"
      :visible.sync="tipVisable"
      width="500px"
    >
      <div style="max-height: 400px; overflow-y: auto;">
        <span>{{ `根据所填写数据，共找到${tipDatas.length}笔平台 / 站点 / 店铺数据，
          其中${tipValue.join('、')}已有数据，无法重复生成，
          确认生成将忽略此部分平台 / 站点 /店铺 + SKu ，是否继续！` }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tipVisable = false">取 消</el-button>
        <el-button type="primary" @click="Tosure">确 定</el-button>
      </span>
    </el-dialog>

    <!-- </el-main> -->
  </el-container>
</template>

<script>
import { querySizeList, queryColorList, LimitedqueryStyleList, preInsertSsr, InsertSsr, ModifySsr, getplatformSiteShop, getSkuInfoList } from '@/api/scm-api'

export default {
  data() {
    return {
      disabled: false,
      Loadingcommit: false,

      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      data: [],
      alldatas: [],

      ruleForm: {
        style: '',
        platformSiteShop: '',
        color: '',
        size: '',
        sku: '',
        returnRate: undefined,
        safeStockDays: undefined
      },
      rules: {
        style: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        platformSiteShop: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }]
      },

      tipVisable: false,
      tipDatas: [],
      tipValue: []
    }
  },
  computed: {
    // 获取输入框的值
    formParams() {
      const { sku, style, color, size, platformSiteShop, returnRate, safeStockDays } = this.ruleForm
      const getstyle = this.styleOptions.find(item => item.id === style)
      const getcolor = color ? this.colorOptions.find(item => item.id === color) : ''
      const getsize = size ? this.sizeOptions.find(item => item.id === size) : ''
      const platformSiteShopVOList = []
      const platformId = []
      const platformName = []
      const siteId = []
      const siteName = []
      const shopId = []
      const shopName = []
      if (Array.isArray(platformSiteShop)) {
        for (let i = 0; i < platformSiteShop.length; i++) {
          const temp1 = platformSiteShop[i][0].split('&&')
          platformId.push(parseInt(temp1[0]))
          platformName.push(temp1[1])
          if (platformSiteShop[i][1]) {
            const temp2 = platformSiteShop[i][1].split('&&')
            siteId.push(parseInt(temp2[0]))
            siteName.push(temp2[1])
            if (platformSiteShop[i][2]) {
              const temp3 = platformSiteShop[i][2].split('&&')
              shopId.push(parseInt(temp3[0]))
              shopName.push(temp3[1])
            }
          }
          platformSiteShopVOList.push({ platformId: platformId[i], platformName: platformName[i], siteId: siteId[i], siteName: siteName[i], shopId: shopId[i], shopName: shopName[i] })
        }
      }
      return Object.assign({}, this.pager, { sku, returnRate, safeStockDays },
        { styleName: getstyle.styleName, styleId: style, colorId: color, colorName: getcolor.colorName,
          sizeName: getsize.size, sizeId: size, platformSiteShopVOList })
    },
    ModifyformParams() {
      const { returnRate, safeStockDays } = this.ruleForm
      return Object.assign({}, this.$route.query, { returnRate, safeStockDays })
    },
    // 生成对应的请求参数
    Parameter() {
      return Object.assign({}, this.formParams)
    }
  },
  mounted() {
    this._queryStyleList()
    this._listPlatformSiteShop()
    this.getModifyTable()
  },
  methods: {
    // 下拉数据处理
    async _queryStyleList() {
      const { datas } = await LimitedqueryStyleList()
      this.styleOptions = datas
    },
    styleSelectChange(value) {
      const data = this.styleOptions.find(item => item.id === value)
      if (data) {
        this._queryColorList(data.id)
        this._querySizeList(data.id)
      }
      this.ruleForm.color = ''
      this.ruleForm.size = ''
      this.ruleForm.sku = ''
    },
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    async _listPlatformSiteShop() {
      const { datas } = await getplatformSiteShop()
      this.getDataTree(datas)
      this.alldatas = datas
    },
    getDataTree(data) {
      if (!Array.isArray(data)) return
      for (let i = 0; i < data.length; i++) {
        data[i].value = `${data[i].dropDownId}&&${data[i].dropDownName}`
        this.getDataTree(data[i].dropDownList)
      }
    },
    // 点击提交按钮将表单数据提交至后台
    handlesubmitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return false
        const { returnRate } = this.ruleForm
        const { id } = this.$route.query
        if (id) {
          returnRate > 60 ? this.$confirm(`退货率大于60%，是否继续提交？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this._modifyturnover()
          }) : this._modifyturnover()
        } else {
          returnRate > 60 ? this.$confirm(`退货率大于60%，是否继续提交？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this._addturnover()
          }) : this._addturnover()
        }
      })
    },
    // 新增
    async _addturnover() {
      try {
        this.Loadingcommit = true
        const Parameter = Object.assign({}, this.Parameter)
        const { datas } = await preInsertSsr(Parameter)
        if (datas) {
          this.tipVisable = true
          this.tipDatas = datas
          const value = []
          for (let i = 0; i < datas.length; i++) {
            value.push(`${datas[i].platformName} / ${datas[i].siteName} / ${datas[i].shopName} + ${datas[i].sku}`)
          }
          // value.join('、')
          this.tipValue = value
        } else {
          await InsertSsr(Parameter)
          this.$notify({
            title: '新增成功',
            message: '新增成功',
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
        }
      } finally {
        this.Loadingcommit = false
      }
    },
    async Tosure() {
      const AddParameter = Object.assign({}, this.Parameter)
      await InsertSsr(AddParameter)
      this.$notify({
        title: '新增成功',
        message: '新增成功',
        type: 'success'
      })
      this.$router.push({
        path: '../',
        append: true
      })
    },
    // 修改
    async _modifyturnover() {
      this.Loadingcommit = true
      // const Parameter = Object.assign({}, this.Parameter)
      const Parameter = Object.assign({}, this.ModifyformParams)
      const { code } = await ModifySsr(Parameter)
      if (code === 0) {
        this.$notify({
          title: '修改成功',
          message: '修改成功',
          type: 'success'
        })
        this.Loadingcommit = false
        this.$router.push({
          path: '../',
          append: true
        })
      }
    },
    // 获取需要修改的的表格
    async getModifyTable() {
      const { id } = this.$route.query
      if (id) {
        this.disabled = true
        this._queryColorList()
        this._querySizeList()
        const modifyForm = this.$route.query
        this.ruleForm.style = modifyForm.styleId
        this.ruleForm.color = modifyForm.colorId
        this.ruleForm.size = modifyForm.sizeId
        this.ruleForm.sku = modifyForm.sku
        this.ruleForm.returnRate = modifyForm.returnRate
        this.ruleForm.safeStockDays = modifyForm.safeStockDays
        const arr = [`${modifyForm.platformId}&&${modifyForm.platformName}`, `${modifyForm.siteId}&&${modifyForm.siteName}`, `${modifyForm.shopId}&&${modifyForm.shopName}`]
        this.ruleForm.platformSiteShop = [arr]
      }
    },
    // sku输入后失去焦点
    async inputBlur(event) {
      if (event.target.value) {
        this._queryColorList()
        this._querySizeList()
        const { code, datas } = await getSkuInfoList(event.target.value)
        if (code === 0) {
          const getstyle = this.styleOptions.find(item => item.styleName === datas.style)
          this.ruleForm.style = getstyle.id
          this.ruleForm.color = datas.colorId
          this.ruleForm.size = datas.sizeId
        }
      }
    },
    // 变化则清空
    colorSelectChange() {
      this.ruleForm.sku = ''
    },
    sizeSelectChange() {
      this.ruleForm.sku = ''
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
  /* element.style{
    width:60%
  } */
</style>
